import { genSize } from '@fifteen/design-system-vue';

import { toRadians } from './angle';

import type CSS from 'csstype';

/**
 * Returns a string containing an `url()` CSS function with the provided URL
 * @param url - The file to include
 * @returns `url()` CSS function as a string
 */
export function genCssUrl(url?: string): string {
  return url ? `url(${url})` : '';
}

/**
 * Helper to return a transform style based on an x and y value (translate)
 * and an angle value (rotate)
 * @param y - x value for the translate
 * @param y - y value for the translate
 * @param angle - rotate value
 * @returns style object
 */
export function transformStyle(
  x: string | number = 0,
  y: string | number = 0,
  angle: CssAngle | number = 0
): CSS.Properties {
  return {
    // prettier-ignore
    transform: `rotate(${toRadians(angle)}rad) translate(${genSize(x)}, ${genSize(y)})`,
  };
}
