<template lang="pug">
.FCardsSlider(
  ref="sliderRef"
  :style="style"
  :class="classes"
)
  .FCardsSlider__navigation(ref="navigationRef")
    slot(name="title")
    .FCardsSlider__navigationArrows
      FButton.FCardsSlider__button(
        icon
        color="secondary"
        size="giant"
        :disabled="selectedItemIndex === 0"
        @click="selectedItemIndex--"
      )
        FIcon(name="arrowLeft")
      FButton.FCardsSlider__button(
        icon
        color="secondary"
        size="giant"
        :disabled="isRightButtonDisabled"
        @click="selectedItemIndex++"
      )
        FIcon(name="arrowRight")
  .FCardsSlider__cards(:class="cardsClasses")
    .FCardsSlider__cards__container(ref="cardsRef")
      slot(name="cards")
</template>

<style lang="stylus">
.FCardsSlider
  display flex
  flex-direction var(--FCardsSlider--flexDirection)
  column-gap rem(192)
  row-gap rem(32)

  &--topNavigation
    flex-direction column

    .FCardsSlider__navigation
      width auto
      display flex
      justify-content space-between
      align-items flex-end

      +media-down('sm')
        width auto
        margin 0
        max-width none
        display block

.FCardsSlider__navigation
  width rem(256)

.FCardsSlider__navigationArrows
  .FButton
    margin-top rem(24)

    &:first-of-type
      margin-right rem(16)

.FCardsSlider__cards
  width 100%
  overflow-x var(--FCardsSlider--overflowX)
  position relative

  &--smooth-borders
    padding-left rem(32)

    &::before
      content ''
      z-index 2
      top 0
      left 0
      height 100%
      width rem(32)
      position absolute
      background-repeat repeat-x
      background-color transparent
      background-image linear-gradient(90deg, #fff, rgba(0, 0, 0, 0))

  +media-down('sm')
    margin-left 0

.FCardsSlider__cards__container
  width 100%
  display flex
  column-gap var(--FCardsSlider--columnGap)
  transform translateX(var(--FCardsSlider--transformX))
  transition transform var(--transition--ease-out) 1s

  & > *
    flex-shrink 0

    +media-down('xs')
      scroll-snap-align start

+media-down('sm')
  .FCardsSlider
    flex-direction column

  .FCardsSlider__cards--smooth-borders
    padding-left rem(24)

    &::before
      width rem(24)

+media-down('xs')
  .FCardsSlider__cards
    scroll-snap-type x mandatory
</style>

<script setup lang="ts">
import type CSS from 'csstype';

export interface FCardsSliderProps {
  /**
   * Current index of the slider
   */
  modelValue?: number;
  /**
   * Use smooth borders in the animation
   */
  smoothBorders?: boolean;
  /**
   * Width of cards, in pixels
   */
  cardWidth?: number;
  /**
   * Width of cards for mobile screen sizes, in pixels
   */
  cardMobileWidth?: number;
  /**
   * Navigation (and title) position
   */
  navigationPosition?: 'top' | 'left';
  /**
   * Gap between cards, in pixels
   */
  horizontalGap?: number;
  /**
   * Allow sliding until the last card even if it is fully in viewport
   */
  slideToEnd?: boolean;
  /**
   * If true, the cards will overflow on the x axis
   */
  overflow?: boolean;
}

const props = withDefaults(defineProps<FCardsSliderProps>(), {
  modelValue: 0,
  smoothBorders: false,
  cardWidth: 416,
  cardMobileWidth: 416,
  navigationPosition: 'left',
  horizontalGap: 32,
  slideToEnd: false,
  overflow: false,
});

const { smAndDown } = useFBreakpoints();

const { width: screenWidth } = useWindowSize({ initialWidth: 0 });

const selectedItemIndex = useVModelProxy({ props, defaultValue: 0 });
const cardsRef = ref<HTMLElement>();

const isRightButtonDisabled = computed(() =>
  props.slideToEnd
    ? selectedItemIndex.value + 1 >= (cardsRef.value?.children?.length ?? 0)
    : selectedItemIndex.value >=
      (cardsRef.value?.children?.length ?? 0) - (cardsOnScreen.value ?? 1)
);

const cardsClasses = computed<VueClasses>(() => ({
  'FCardsSlider__cards--smooth-borders': props.smoothBorders,
}));

const containerTransformX = computed(
  () =>
    (smAndDown(props.cardMobileWidth, props.cardWidth) + props.horizontalGap) *
    -selectedItemIndex.value
);

const sliderFlexDirection = computed<CSS.Property.FlexDirection>(() => {
  switch (props.navigationPosition) {
    case 'top':
      return 'column';
    case 'left':
    default:
      return 'row';
  }
});

const sliderRef = ref<HTMLElement>();
const sliderWidth = computed(() => {
  const windowWidth = screenWidth.value; // hack to force recompute on window resize
  return sliderRef.value?.getBoundingClientRect().width ?? windowWidth;
});

const navigationRef = ref<HTMLElement>();
const navigationWidth = computed(() =>
  props.navigationPosition === 'left'
    ? smAndDown(
        0,
        (navigationRef.value?.getBoundingClientRect().width ?? 0) + 192
      )
    : 0
);

const cardsOnScreen = computed<number>(() => {
  const cardWidth = smAndDown(props.cardMobileWidth, props.cardWidth);
  return Math.floor(
    (sliderWidth.value - navigationWidth.value + props.horizontalGap) /
      (cardWidth + props.horizontalGap)
  );
});

const style = computed(
  (): Style => ({
    '--FCardsSlider--flexDirection': sliderFlexDirection.value,
    '--FCardsSlider--transformX': genSize(containerTransformX.value),
    '--FCardsSlider--columnGap': genSize(props.horizontalGap),
    '--FCardsSlider--overflowX': props.overflow ? 'visible' : 'hidden',
  })
);

const classes = computed<VueClasses>(() => ({
  'FCardsSlider--topNavigation': props.navigationPosition === 'top',
}));
</script>
